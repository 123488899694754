<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :style="iconStyle"
      d="M18.3334 2.99992C18.3334 2.08325 17.5834 1.33325 16.6667 1.33325H3.33341C2.41675 1.33325 1.66675 2.08325 1.66675 2.99992M18.3334 2.99992V12.9999C18.3334 13.9166 17.5834 14.6666 16.6667 14.6666H3.33341C2.41675 14.6666 1.66675 13.9166 1.66675 12.9999V2.99992M18.3334 2.99992L10.0001 8.83325L1.66675 2.99992"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '20',
    },
    width: {
      type: String,
      default: '20',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
